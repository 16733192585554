@import "../../assets/sass/helper";


.box-gallery-vertical {
  position: relative;


  .mask-bg {
    @include media_991(min) {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(70% + 20px);
      height: 100%;
      z-index: -1;
    }
    @include media_991() {
      display: none;
    }
  }


  .inner-img {
    width: 100%;
    height: 100%;


    @include media_991(min) {
      min-height: 80vh;
      padding-right: 50px;
      .dsn-parallax-img {
        position: absolute;
        width: 100%;
      }
    }

  }

  @include media_991(min) {
    .item-box, .item-box .box-text {
      padding-top: $margin-padding;
      padding-bottom: $margin-padding;
    }
    .box-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 50px;
      height: 100%;
    }
  }

  @include media_991(){
    .box-text{
      margin-top: 30px;
    }
  }

}
