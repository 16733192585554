$component: '.c-accordion';

#{$component} {
  list-style: none;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  &__item {
    --cover: #3e66a0;
    background-position: center center;
    background-size: cover;
    background-color: #3e66a0;
    background-blend-mode: luminosity;

    position: relative;
    flex-grow: 1;
    height: 100%;
    width: 100px;
    min-width: 2.05rem;
    transition: all 0.5s ease-in-out;

    &:hover
    {
      _flex-grow: 2;
      width: 100%;
      background-color: transparent;
      opacity:1;
      svg {
        opacity: 1;
        transform: rotate(-180deg);
        transition-property: transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 1s;
      }
      .image-zoom {
        opacity: 1;
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.9s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: bounceInDown;
        animation-name: bounceInDown;
        transition-timing-function: ease-in;
      }
      #{$component} {
        &__title {
          &--hover-hide {
            max-height: 0;
            opacity: 0;
          }
          &--hover-show {
            opacity: 1;
            max-width: 100%;
            -webkit-animation-duration: 0.5s;
            animation-duration: 0.8s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-name: bounceInDown;
            animation-name: bounceInDown;
            transition-timing-function: ease-in;
          }
        }
        &__description {
          opacity: 1;
          font-size: 22px !important;
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.8s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-name: bounceInDown;
          animation-name: bounceInDown;
          transition-timing-function: ease-in;
        }
      }
    }
    &:not(&:hover) {
      --cover: #fff;
      background-position: left;
      background-size: cover;
      background-color: #fff;
      background-blend-mode: luminosity;

      position: relative;
      flex-grow: 1;
      height: 100%;
      width: 100px;
      min-width: 2.05rem;
      transition: all 0.5s ease-in-out;
      
      svg {
        opacity: 0;
      }
      #{$component} {
        &__title {
          &--hover-hide {
            max-height: 100%;
            opacity: 1;
          }
          &--hover-show {
            opacity: 0;
          }
        }
        &__description {
          opacity: 0;
        }
      }
    }
    

    &:hover {
      cursor: pointer;
    }
  }
  svg {
    opacity: 0;
    position: absolute;
    top: 3%;
    right: 7%;
    transition: all 0.3s ease-in-out;
  }
  .image-zoom {
   margin-top: 25px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    button {
      background-color: #fff !important;
      border-radius: 100px;
      color: #000 !important;
      font-size: large !important;
      font-weight: 600 !important;
    }
  }
  &__action {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
  &__title {
    color: white;
    max-height: 100%;
    max-width: 100%;
    width:100%;
    overflow: hidden;
    &--hover-show {
      opacity: 0;
      width: 100%;
    }
    &--hero {
      font-size: 3.5rem;
      font-weight: 600;
      line-height: 150%;
    }
  }
  &__aside {
    padding: 1rem;
    position: absolute;
    height: 100%;
    right: 1rem;
    bottom: 0;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    &:before {
      content: '+';
      color: yellow;
      margin-bottom: 1rem;
      font-size: 2rem;
      display: inline-block;
    }
    &:after {
      content: '';
      flex-grow: 1;
      width: 1px;
      display: block;
      margin-top: 1rem;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  &__content {
    padding: 2rem 2.5rem 0 2rem;
    width: 700px;
    position: absolute;
    top: 50%;
    left: 83%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__description {
    color: white;
    font-weight: 400;
    font-size: x-large !important;
    line-height: 30px;
    max-height: 100%;
    max-width: 400px;
    opacity: 0;
    margin: 0;
  }
}

.hero-mobile-container{
  position: relative;
  justify-content: center;
  align-items: center;
}

.hero-mobile-description {
  position: absolute;
  top: 75%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30%;
  padding: 10px;
  p {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
  .btn-hero-mobile{
    margin-top: 20px;
    background-color: #fff !important;
    border-radius: 100px;
    color: #000 !important;
    font-size: medium !important;
    font-weight: 300 !important;
  }
}

.bg-full {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  80% {
    opacity: 0.8;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
