@import "../../assets/sass/helper";

.blog-item {
    .box-img {
        position: relative;
        overflow: hidden;

        img {
        }
    }

    .box-content {
        position: relative;
        bottom: 50px;
        margin-left: 50px;
        margin-bottom: -50px;
        padding: 40px;
        z-index: 1;
    }
}
